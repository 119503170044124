import React from 'react';
import popupImg from "../images/popup.jpg";

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
    this.componentDidMount = this.componentDidMount.bind(this);
  }
  

  componentDidMount() {
    
  }

  render() {
    const { errors } = this.state;
    return (
      <div className='popup-outer'>
        <div className="Popup_inner">
          <i className="fas fa-times fa-2x" name='remove' onClick={this.props.closePopup} style={{ float: 'right', color: '#C0C0C0', marginTop: '-1.5rem', marginRight: '-1.5rem', cursor: 'pointer' }} />
          <div className='popheader'>
          <a href="https://sdas.jobs.cz/volna-mista/?customcateg%5B%5D=revitrans" target="_blank"><img src={popupImg} /></a>
          </div>
          <div className='popcontent'>
            
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;