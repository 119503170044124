import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Preloader from "../Preloader";
import { Redirect } from 'react-router-dom';
import kosodelnik from "../../images/kosodelnik.png";
import kosodelnikModry from "../../images/kosodelnik-modry.png";

class Contact extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            bodyPost: {},
            isLoading: false,
            index: 1,
            headerBgClass: "home",
            firstRectSrc: kosodelnikModry,
            secondRectSrc: kosodelnik, 
            nextHeaderBgIndex: 2,
            firstArticle: "",
            secondArticle: "hidden",
            error: {},
            redirectInternalError: false,
        };

        this.bgToggle = this.bgToggle.bind(this);
        this.ContactToggle = this.ContactToggle.bind(this);
    }

    bgToggle(number) {
        if (number === 1) {
          this.setState({
              firstRectSrc: kosodelnikModry,
              secondRectSrc: kosodelnik,
              headerBgClass: "home",
              nextHeaderBgIndex: 2,
              firstArticle: "",
              secondArticle: "hidden"
          });
        } else {
            this.setState({
                firstRectSrc: kosodelnik,
                secondRectSrc: kosodelnikModry,
                headerBgClass: "recultivation",
                nextHeaderBgIndex: 1,
                firstArticle: "hidden",
                secondArticle: ""
            });
        }
      }

      ContactToggle(section){
        this.setState({
            [section]: this.state[section] === "hidden" ? "" : "hidden"
        });
      }

    componentDidMount(){

        this.interval = setInterval(() => this.bgToggle(this.state.nextHeaderBgIndex), 14000);
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }

    renderHeader = () => {
        return(
            <header className={"contact-header " + this.state.headerBgClass}>
                <Navbar/>
    
                <div className="sd-container">
                    <section id="sub-header">
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center sub-header-sub-caption-visibility">
                                    <h6 className="sub-header-sub-caption text-uppercase">Zde najdete nejdůležitější</h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="sub-header-caption text-uppercase">Kontakty</h6>
                                </div> 
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="sub-header-chevrons">
                                    <img
                                        src={this.state.firstRectSrc}
                                        alt="chevron_white"
                                        className="first header-toogle-button"
                                        onClick={() => this.bgToggle(1)}
                                    />
                                    <img
                                        src={this.state.secondRectSrc}
                                        alt="chevron_sd"
                                        className="second header-toogle-button"
                                        onClick={() => this.bgToggle(2)}
                                    />
                                    </span>
                                </div>                      
                            </div>                        
                        </div>
    
                        <div className="row">
                            <div className="col-md-4">
                                <h5 className="text-white text-uppercase mb-3">Adresa</h5>
                                <p>
                                    Revitrans a.s. <br />
                                    Důlní čp. 429 <br />
                                    418 01 Bílina 
                                </p>
                            </div>
                            <div className="col-md-4">
                                <h5 className="text-white text-uppercase mb-3">Fakturační údaje</h5>
                                <p>
                                    IČ: 25028197 <br />
                                    DIČ: CZ699005746<br />
                                </p>
                            </div>
                            <div className="col-md-4">
                                <h5 className="text-white text-uppercase mb-3">&nbsp;</h5>
                                <p>Společnost je zapsána v obchodním rejstříku vedeném Krajským soudem v Ústí nad Labem odd. B, vložka 1059.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </header> 
        );
    }
    
    renderMainContainer = () => {
       
        return(
            <section id="main-container" className="mb-5">
                <div className="sd-container">

                    <section id="about" className="p-3">
                        <h1 className="text-uppercase">Kontaktní údaje</h1>
                    </section>
    
                    <section id="detail-contact" className="pb-3 pt-3 pl-3 pl-md-5 pr-3 pr-md-5">
                        <div className="row">
                            <div className="col-12">
                                <div className="col-12 d-flex justify-content-between pl-0  pr-0">
                                    <h4 className="text-white text-uppercase pt-4 pb-2 sd-hidden-xs">Telefon</h4>
                                    <h4 className="text-white text-uppercase pt-4 pb-2 sd-visible-xs">Telefon</h4>
                                </div>
                                <div className="pl-0 pr-0 pb-4 ">
                                    <div className="row pb-2">
                                        <div className="col-sm-12 col-md-6">Dispečink Revitrans Bílina</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 417 805 820</div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">Dispečink Revitrans Tušimice</div>
                                        <div className="col-sm-12 col-md-6 detail-contact-text-position">+420 474 602 069</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Fax</h4>
                            </div>
                            <div className="col-12 pl-0 pr-0 d-flex justify-content-between pb-4 ">
                                <div className="col-sm-12 col-md-6 sd-hidden-xs">
                                </div>
                                <div className="col-sm-12 col-md-6 detail-contact-text-position"><i className="fas fa-fax"></i> +420 417 805 870<br /><i className="fas fa-fax"></i> +420 417 805 830</div>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                    <h4 className="text-white text-uppercase pt-4 pb-2">E-mail</h4>
                            </div>
                            <div className="col-12 pl-0 pr-0 d-flex justify-content-between pb-4 ">
                                <div className="col-sm-12 col-md-6 sd-hidden-xs">
                                </div>
                                <div className="col-sm-12 col-md-6 detail-contact-text-position">info@revitrans.cz</div>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col">
                                <hr className="sd-contact-hr m-0" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                    <h4 className="text-white text-uppercase pt-4 pb-2">Komunikace s médii</h4>
                            </div>
                            <div className="col-12 pl-0 pr-0 d-flex justify-content-between pb-4 ">
                                <div className="col-sm-12 col-md-6 sd-hidden-xs">Ing. Lukáš Kopecký, DiS
                                </div>
                                <div className="col-sm-12 col-md-6 detail-contact-text-position"><span className="sd-visible-xs">Ing. Lukáš Kopecký, DiS.</span>+420 474 604 322<br />+420 777 780 017</div>
                            </div>
                        </div>
    
                    </section>
                </div>
            </section>
        );
    }
    
    renderSecondaryContainer = () => {
        return(
            <section id="secondary-container" className="sd-hidden-xs contact-secondary-container"></section>
        );
    }
    
    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>           
        );
    }
    
    render(){
        const { isLoading, redirectInternalError, error } = this.state;
    
        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                ) : (redirectInternalError ? (
                    <Redirect to={{
                        pathname: "/500",
                        state: { error: error }
                      }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}
    
                        {this.renderMainContainer()}
    
                        {this.renderSecondaryContainer()}
    
                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default Contact;