import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// import "core-js/modules/es.symbol";
// import "core-js/es/map";
// import "core-js/es/set";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import './utils/bootstrap/css/bootstrap.css';
import './css/styles.css';
import './css/preloader.css';
import './utils/fontawesome/css/all.css';

ReactDOM.render(<App />, document.getElementById('root'));
