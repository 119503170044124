import React from "react";
import axios from "axios";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Popup from "../Popup";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import kosodelnik from "../../images/kosodelnik.png";
import kosodelnikModry from "../../images/kosodelnik-modry.png";
import home1 from "../../images/home1.jpg";
import home2 from "../../images/home2.jpg";
import home3 from "../../images/home3.jpg";
import {
  WORDPRESS_URL,
  WP_POSTS_SLUG,
} from "../../utils/constants";
import Preloader from "../Preloader";

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        bodyPost: { idx: "", title: "", text: "", shortText: "", slug: "", date: "" },
        index: 1,
        headerBgClass: "home",
        firstRectSrc: kosodelnikModry,
        secondRectSrc: kosodelnik, 
        nextHeaderBgIndex: 2,
        firstArticle: "",
        secondArticle: "hidden",
        whoWeAreImg: home1,
        whoWeAre: [home1, home2, home3],
        whoWeAreIndex: 1,
        showPopup: true,
        error: "",
        isLoading: true
    };

    this.bgToggle = this.bgToggle.bind(this);
  }

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }

  whoWeAreToggle(direction) {
    if (direction === "next") {
      if (this.state.whoWeAreIndex === this.state.whoWeAre.length) {
        this.setState({
            whoWeAreIndex: 1,
            whoWeAreImg: this.state.whoWeAre[0]
        });
      } else {
        let idx = this.state.whoWeAreIndex + 1;
        this.setState({
            whoWeAreIndex: idx,
            whoWeAreImg: this.state.whoWeAre[idx - 1]
        });
      }
    } else {
      if (this.state.whoWeAreIndex === 1) {
        let idx = this.state.whoWeAre.length;
        this.setState({
            whoWeAreIndex: idx,
            whoWeAreImg: this.state.whoWeAre[idx - 1]
        });
      } else {
        let idx = this.state.whoWeAreIndex - 1;
        this.setState({
            whoWeAreIndex: idx,
            whoWeAreImg: this.state.whoWeAre[idx - 1]
        });
      }
    }
  }

  bgToggle(number) {
    if (number === 1) {
      this.setState({
          firstRectSrc: kosodelnikModry,
          secondRectSrc: kosodelnik,
          headerBgClass: "home",
          nextHeaderBgIndex: 2,
          firstArticle: "",
          secondArticle: "hidden"
      });
    } else {
        this.setState({
            firstRectSrc: kosodelnik,
            secondRectSrc: kosodelnikModry,
            headerBgClass: "recultivation",
            nextHeaderBgIndex: 1,
            firstArticle: "hidden",
            secondArticle: ""
        });
    }
  }

  componentDidMount() {
    if(!localStorage.POPUP || localStorage.POPUP < new Date()){
        var date = new Date();
        date.setDate(date.getDate() + 1);
        localStorage.setItem("POPUP", date);
    }
    else{
        this.setState({showPopup:false});
    }

    const WHO_WE_ARE = "kdo-jsme";

        axios.get(WORDPRESS_URL + WP_POSTS_SLUG + WHO_WE_ARE)
      .then((req1) => {
          this.setState({
            bodyPost: {
                idx: req1.data[0].id,
                title: req1.data[0].title.rendered,
                text: req1.data[0].content.rendered, 
                shortText: req1.data[0].excerpt.rendered,
                slug: req1.data[0].slug,
                date: req1.data[0].date
            },
            isLoading: false
          });


        });

      this.interval = setInterval(() => this.bgToggle(this.state.nextHeaderBgIndex), 14000);
  }

  componentWillUnmount() {

    clearInterval(this.interval);
  }

renderHeader = () => {
    return(
        <header className={this.state.headerBgClass}>
            <Navbar />
            
            <div className="sd-container">
                <section id="sub-header">
                <div className="row">
                    <div className="col-md-7 col-xl-8 ">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="sub-header-sub-caption text-uppercase">
                        Revitrans
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="sub-header-caption text-uppercase">
                        Revitalizace
                        <br />
                        a transport
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="sub-header-chevrons">
                        <img
                            src={this.state.firstRectSrc}
                            alt="chevron_white"
                            className="first header-toogle-button"
                            onClick={() => this.bgToggle(1)}
                        />
                        <img
                            src={this.state.secondRectSrc}
                            alt="chevron_sd"
                            className="second header-toogle-button"
                            onClick={() => this.bgToggle(2)}
                        />
                        </span>
                    </div>
                    </div>
                    <div className={"col-md-5 col-xl-4 " + this.state.firstArticle}>
                    <div className="d-flex justify-content-between align-items-center sd-mt-4">
                        <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                        Revitalizace
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="sub-header-text">
                        Obnova a tvorba nové krajiny po ukončení důlní činnosti je jednou z priorit skupiny Severočeské doly. 
                        Tento proces zajišťuje společnost Revitrans, a.s., provozním úsekem Rekultivace.
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                    <Link
                        to={"/clanek/revitalizace"}
                        className="who-we-are-more-btn-link"
                        >
                        <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                        <span className="btn-text text-white text-uppercase pl-4">
                            Zjistit více{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                        </span>
                        </div>
                        </Link>
                    </div>
                    </div>

                    <div className={"col-md-5 col-xl-4 " + this.state.secondArticle}>
                    <div className="d-flex justify-content-between align-items-center sd-mt-4">
                        <h6 className="sub-header-sub-caption text-uppercase sd-hidden-xs">
                        Transport
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="sub-header-text">
                        Hlavním cílem úseku autodopravy je zajistit přepravní výkony pro společnosti skupiny Severočeské doly, což představuje zejména přepravu sypkých hmot.
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                    <Link
                        to={"/clanek/transport"}
                        className="who-we-are-more-btn-link"
                        >
                        <div className="sub-header-more-btn d-flex justify-content-between align-items-center">
                        <span className="btn-text text-white text-uppercase pl-4">
                            Zjistit více{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                        </span>
                        </div>
                        </Link>
                    </div> 
                    </div>
                </div>
                </section>
            </div>
        </header>
    );
}

renderMainContainer = () => {
    const { bodyPost, whoWeAreImg, whoWeAreIndex, whoWeAre } = this.state;
    return(
        <section id="main-container">
            <div className="sd-container">
                <section id="pictured-navigation">
                <div className="row justify-content-center">
                    <Link
                        to={"/clanek/revitalizace"}
                        className="col-sm-12 col-md-3 no-padding tile-link"
                    >
                        <div className="o-padding pic-nav first-pic-nav justify-content-center align-items-center d-flex">
                            <span className="btn-text text-uppercase pic-nav-padding text-center">
                                Revitalizace
                            </span>
                        </div>
                    </Link>
                    <Link
                        to={"/clanek/transport"}
                        className="col-sm-12 col-md-3 no-padding tile-link"
                    >
                        <div className="no-padding pic-nav second-pic-nav justify-content-center align-items-center d-flex">
                            <span className="btn-text text-uppercase pic-nav-padding text-center">
                                Transport
                            </span>
                        </div>
                    </Link>
                    <Link
                        to={"/clanek/vrtne-a-trhaci-prace"}
                        className="col-sm-12 col-md-3 no-padding tile-link"
                    >
                        <div className="no-padding pic-nav third-pic-nav justify-content-center align-items-center d-flex">
                            <span className="btn-text text-uppercase pic-nav-padding text-center">
                                Vrtné a trhací práce
                            </span>
                        </div>
                    </Link>
                    <Link
                        to={"/autorizovany-servis-tatra"}
                        className="col-sm-12 col-md-3 no-padding tile-link"
                    >
                        <div className="no-padding pic-nav fourth-pic-nav justify-content-center align-items-center d-flex">
                            <span className="btn-text text-uppercase pic-nav-padding text-center">
                                Autorizovaný servis Tatra
                            </span>
                        </div>
                    </Link>
                </div>
                </section>

                <section id="who-we-are">
                <div className="row">
                    <div className="col-md-6">
                    <div className="d-flex justify-content-center align-items-center who-we-are" style={{backgroundImage:`url("${whoWeAreImg}")`}}>
                        <div className="who-we-are-paging">
                        <div className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link" onClick={() => this.whoWeAreToggle("back")}>
                            <i className="fa fa-chevron-left"></i>
                        </div>
                        <div className="col-4 h-100 no-padding d-flex justify-content-center align-items-center">
                            {whoWeAreIndex}/{whoWeAre.length}
                        </div>
                        <div className="col-4 h-100 no-padding d-flex justify-content-center align-items-center link" onClick={() => this.whoWeAreToggle("next")}>
                            <i className="fa fa-chevron-right"></i>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 p-0-50">
                    <div className="d-flex justify-content-between align-items-center mt-4-v2">
                        <h6 className="who-we-are-sub-caption text-uppercase">
                        Zjistěte
                        </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="who-we-are-caption text-uppercase">
                        {bodyPost.title}
                        </h2>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="who-we-are-text">
                        {renderHTML(bodyPost.text)}
                        </div>
                    </div>
                    <div className="row who-we-are-gallery mb-4">
                        <div className="col-4 p-1">
                        <a href={home1} data-lightbox="group"><img src={home1} alt="thumbnail-galerie-clanek" /></a>
                        </div>
                        <div className="col-4 p-1">
                        <a href={home2} data-lightbox="group"><img src={home2} alt="thumbnail-galerie-clanek" /></a>
                        </div>
                        <div className="col-4 p-1">
                        <a href={home3} data-lightbox="group"><img src={home3} alt="thumbnail-galerie-clanek" /></a>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <Link
                        to={"/clanek/o-spolecnosti"}
                        className="who-we-are-more-btn-link"
                        >
                        <div className="who-we-are-more-btn text-white d-flex align-items-center">
                            <span className="btn-text text-uppercase">
                            Více o nás{" "}
                            <i className="fa fa-chevron-right ml-2"></i>
                            </span>
                        </div>
                        </Link>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        </section>
    );
}

renderSecondaryContainer = () => {
    return(
        <section id="secondary-container"></section>
    );
}

renderFooter = () => {

    return(
        <footer>
            <div className="sd-container">
                <section id="footer-content" className="footer-top-padding">
                    <Footer />
                </section>
            </div>
        </footer>            
    );
}

render() {
    const { isLoading } = this.state; 
    //const isLoading  = true; 

    return (  
        <section id="web">
            <div id="preload">
                {/* <img src={require('../../images/header-bg.jpg')}></img> */}
            </div>

            {isLoading ? (
                <Preloader isLoaded={!isLoading} />
            ) : ( 
                <div className="wrapper">
                    {this.renderHeader()}

                    {this.renderMainContainer()}

                    {this.renderSecondaryContainer()}

                    {this.renderFooter()}

                    {this.state.showPopup ?
                        <Popup closePopup={() => this.togglePopup()} />
                        : null
                    }

                </div>
            )}
        </section>
    );
  }
}

export default Homepage;