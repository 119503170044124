import React from 'react';
import axios from 'axios';
import Footer from '../Footer'
import renderHTML from 'react-render-html';
import Navbar from '../Navbar';
import Preloader from "../Preloader";
import {
  WORDPRESS_URL,
  WP_POSTS_SLUG_WITH_FEATUREDMEDIA
} from "../../utils/constants";
import { Redirect } from 'react-router-dom';
import defaultBg from '../../images/bg-new2.jpg'
import $ from "jquery";
require('lightbox2');

class Detail extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            post: {
                idx: "",
                title: "",
                text: "", 
                shortText: "",
                slug: "",
                date: "",
                featuredMedia: `url("${defaultBg}")`
            },
            isLoading: true, 
            error: {},
            redirectInternalError: false
        }
    }

    componentDidMount(){
        const slug = this.props.match.params.slug;        

        axios.get( WORDPRESS_URL + WP_POSTS_SLUG_WITH_FEATUREDMEDIA + slug)
        .then(res => {
            if (res.data[0] !== undefined) {
                // let featuredMediaLinks = res.data[0]._embedded['wp:featuredmedia'] ? res.data[0]._embedded['wp:featuredmedia'] : null;
                // let featuredMediaLink = featuredMediaLinks ? `url("${featuredMediaLinks[0].source_url}")` : `url("${defaultBg}")`; // rest api na získání úvodního obrázku
                            
                let post = {
                    idx: res.data[0].id,
                    title: res.data[0].title.rendered,
                    text: res.data[0].content.rendered, 
                    shortText: res.data[0].excerpt.rendered,
                    slug: res.data[0].slug,
                    date: res.data[0].date,
                    // featuredMedia: featuredMediaLink
                }
    
                this.setState({
                    post: post,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });

                this.props.history.push('/404');
            } 
        })
        .catch( error => {
            console.log(error);

            this.setState({ 
                error: error, 
                isLoading: false,
                redirectInternalError: true
            });                    
        });
    }

    renderHeader = () => {
        const { post } = this.state;

        return(
            <header className="detail-header h-100" style={{backgroundImage: post.featuredMedia }}>
                <Navbar/>
        
        
                <div className="sd-container ">
                    <section id="sub-header" className="pb-5">
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h1 className="sub-header-caption text-uppercase detail-sub-header-caption  text-white">{renderHTML(post.title)}</h1>
                                </div>                       
                            </div>                        
                        </div>     
                        
                        <div className="row">
                            <div className="col-12 d-flex detail-read-more" onClick={()=>{window.scrollTo(0, 700);}}>
                                <h3 className="text-uppercase text-white"><bb>Čtěte</bb> dál</h3>
                                <i className="fa fa-chevron-down sd-text-primary ml-3 fa-2x"></i>
                            </div>
                        </div>
                    </section>
                </div>
            </header> 
        );
    }

    renderMainContainer = () => {
        const { post } = this.state;

        return(
            <section id="main-container">
                <div className="sd-container">
        
                    <div className="detail-content detail-content-padding">
                        <div className="detail-title text-uppercase mb-4">
                            <h2>
                                {renderHTML(post.shortText)}
                            </h2>
                        </div>
        
                        <div className="detail-text">  
                            {renderHTML(post.text)}
                        </div>
                    </div>
                    
                </div>
            </section>
        );
    }

    renderFooter = () => {
        return(
            <footer className="footer-contact-height">
                <div className="sd-container">
                    <section id="footer-content" className="footer-top-padding footer-top-padding-contact">
                        <Footer/>
                    </section>
                </div>
            </footer>         
        );
    }

    render(){
        const { isLoading, redirectInternalError, error } = this.state;

        return (            
            <section id="web">
                {isLoading ? (
                    <Preloader isLoaded={!isLoading} />
                    ) : (redirectInternalError ? (
                        <Redirect to={{
                            pathname: "/500",
                            state: { error: error }
                          }} />
                ) : ( 
                    <div className="wrapper">
                        {this.renderHeader()}

                        {this.renderMainContainer()}

                        {this.renderFooter()}
                    </div>
                ))}
            </section>        
        )
    }
}

export default Detail;