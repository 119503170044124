import React from 'react';
import logo from '../images/logo-loader.png';
import bg from '../images/pozadi_trojuhelniky_sd.png';

class Preloader extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            percentageLoaded: 0
        }
    }

    componentDidMount() {
        this.startLoading();
    }

    startLoading = () => {
        this.setState({
            percentageLoaded: this.state.percentageLoaded += 1
        });

        this.intervalID = setTimeout(this.startLoading.bind(this), 50);

        if (this.props.isLoaded || this.state.percentageLoaded === 100) {
            clearInterval(this.intervalID);
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }


    render() {
        const { percentageLoaded } = this.state;
        
        return (
            <div>
                <section id="main-container">
            <div className="preload"> 
                <div className="preload-front">               
                    <div className="preload-content">

                        <div className="loader-frame">
                            <div className="loader1" id = "loader1"></div>
                            <div className="loader2" id = "loader2"></div>    
                            <div className="loader-percentage">{percentageLoaded}%</div>
                        </div>
                        <div>
                            <img src={logo} alt="loader" className="loader"/>
                            {/* <div className="loader-black-text"><b>Severočeské doly a.s.</b></div>
                            <div className="loader-white-text"><b>člen Skupiny ČEZ</b></div> */}
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <section id="secondary-preloader-container" className="preloader-secondary-container">
                <img src={bg} alt="background"/>
                <div>&nbsp;</div>
            </section>
            </div>
        );
    }
}

export default Preloader;