import React from 'react';
import Homepage from './components/pages/Homepage';
import Contact from './components/pages/Contact';
import Detail from "./components/pages/Detail";
import Tatra from './components/pages/Tatra';
import NotFound from './components/pages/NotFound';
import SearchResults from './components/pages/SearchResults';
import InternalServerError from './components/pages/InternalServerError';
import { BrowserRouter, Switch, Route } from "react-router-dom";

export const routeCodes = {
  HOME: '/',  
  DETAILPOST: '/clanek/:slug',
  TATRA: '/autorizovany-servis-tatra',
  CONTACT: '/kontakty',
  SEARCHRESULTS: '/hledat/:search',
  };

class App extends React.Component {
	render() {
		return (
      <BrowserRouter basename="">
        <Switch>
          <Route path={ routeCodes.HOME } component={Homepage} exact />
          <Route path={ routeCodes.DETAILPOST } component={Detail} exact /> 
          <Route path={ routeCodes.TATRA } component={Tatra} exact /> 
          <Route path={ routeCodes.CONTACT } component={Contact} exact />
          <Route path={ routeCodes.SEARCHRESULTS } component={SearchResults} exact />
          <Route path="/500" component={InternalServerError} />
          <Route path="*" component={NotFound} />        
        </Switch>
      </BrowserRouter>
		);
	}
}

export default App;
