// export const WORDPRESS_URL = "http://localhost:91";
//export const WORDPRESS_URL = "http://sdas.sd-portal.cz";
export const WORDPRESS_URL = "https://www.revitrans.cz";
export const WP_POSTS_SLUG = "/wp-json/wp/v2/posts?slug=";
export const WP_POSTS_SEARCH = "/wp-json/wp/v2/posts?search=";
export const WP_POSTS_SEARCH_WITH_FEATUREDMEDIA = "/wp-json/wp/v2/posts?_embed=true&search=";
export const WP_CATEGORIES_SLUG = "/wp-json/wp/v2/categories?slug=";
export const WP_POSTS_CATEGORIES = "/wp-json/wp/v2/posts?categories[]=";
export const WP_POST_CATEGORIES_WITH_FEATUREDMEDIA = "/wp-json/wp/v2/posts?_embed=true&categories[]=";
export const WP_POSTS_SLUG_WITH_FEATUREDMEDIA = "/wp-json/wp/v2/posts?_embed=true&slug=";
export const BASE_API_URL = "http://bint:9090/sd.oko.api";
export const SDAS_API_URL = "http://bint:9090/sd.sdasweb.api";
//export const SDAS_API_URL = "http://localhost:58879";
export const TOKEN_URL = "http://bint:9090/sd.oko.api/token";
